import { Filter, SelectInput, TextInput } from 'react-admin';
import React from 'react';

const TaskFilter = (props: any) => {
  return (
    <Filter {...props} >
      <TextInput placeholder='Task name' source='name' resettable alwaysOn />
      <TextInput placeholder='Assignee' source='assignee' resettable alwaysOn />
      <SelectInput placeholder='Priority' source='priority' choices={[ { id: 'high', name: 'High' }, { id: 'medium', name: 'Medium' }, { id: 'low', name: 'Low' } ]} resettable alwaysOn />
    </Filter>
  )
};

export default TaskFilter;
