import { SelectInput, useCreate, useCreateSuggestionContext, useTheme } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, Grid,
  TextField,
} from '@mui/material';
import React from 'react';

const CreateTag = (props: any) => {
  const [create] = useCreate();
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || '');
  const [desc, setDesc] = React.useState('');
  const [color, setColor] = React.useState('');

  const [theme] = useTheme();

  const colors = [
    { id: 'default', name: 'default', colorBlackTheme: '#4c4c4c', colorWhiteTheme: '#eeeeee' },
    { id: 'primary', name: 'primary', colorBlackTheme: '#90caf9', colorWhiteTheme: '#1a76d2' },
    { id: 'secondary', name: 'secondary', colorBlackTheme: '#ce93d8', colorWhiteTheme: '#2096f3' },
    { id: 'error', name: 'error', colorBlackTheme: '#f44335', colorWhiteTheme: '#d32e2f' },
    { id: 'info', name: 'info', colorBlackTheme: '#29b6f6', colorWhiteTheme: '#0188d1' },
    { id: 'success', name: 'success', colorBlackTheme: '#66bb6a', colorWhiteTheme: '#2f7d32' },
    { id: 'warning', name: 'warning', colorBlackTheme: '#ffa726', colorWhiteTheme: '#ed6d02' },
  ];

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    const url = window.location.href;

    let tagType;
    if (url.includes('accounts')) {
      tagType = 'accounts';
    } else if (url.includes('sim-cards')) {
      tagType = 'sim_card';
    } else if (url.includes('tasks')) {
      tagType = 'tasks';
    }

    event.preventDefault();
    await create(
      'tags',
      { data: { name: value, description: desc, color: color, type: tagType } },
      {
        onSuccess: (data) => {
          setValue('');
          setDesc('');
          onCreate(data);
        },
      },
    );
  };

  return (
    <Dialog {...props} open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container sx={{ pl: '10px', pr: '30px' }}>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <TextField
                label='New tag name'
                value={value}
                onChange={event => setValue(event.target.value)}
                autoFocus
              />
              <SelectInput
                {...props}
                source="color"
                value={color}
                onChange={event => setColor(event.target.value)}
                helperText={false}
                choices={colors}
                optionText={(choice) => {
                  return (
                    <div style={{ backgroundColor: theme === 'dark' ? choice.colorBlackTheme : choice.colorWhiteTheme }} title={choice.name}>
                      <span>{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}</span>
                    </div>
                  )
                }}
                optionValue='id'
                resettable
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <TextField
                sx={{ width: '100%' }}
                multiline
                label='New tag description'
                value={desc}
                onChange={event => setDesc(event.target.value)}
                autoFocus
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='submit'>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateTag;
