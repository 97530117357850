import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  DateInput,
  Edit, SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetList,
} from 'react-admin';
import { Grid, MenuItem } from '@mui/material';
import CreateTag from '../tags/CreateTag';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: '16px',
  },
});

const SimCardEdit = (props: any) => {
  const [tags, setTags] = useState([]);

  const tagsList: any = useGetList(
    'tags?type=sim_card&',
    {
      sort: { field: 'login', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (tagsList.data) {
      setTags(tagsList.data?.map((record: any) => ({ id: record._id, name: record.name, tooltip: record.description })));
    }
  }, [tagsList.data]);

  return (
    <Edit
      title='Edit Account'
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm>
        <Grid container sx={{ pl: '10px', pr: '30px' }}>
          <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
            <TextInput source='phoneNumber' required/>
            <TextInput source='operator' />
            <SelectInput source='owner' choices={[{ id: 'Євгенія', name: 'Євгенія' }, { id: 'Діана', name: 'Діана' }, { id: 'Денис', name: 'Денис' }]} />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
            <DateInput source='expirationPeriod'/>
            <AutocompleteArrayInput
              {...props}
              source="status"
              helperText={false}
              choices={tags}
              create={<CreateTag />}
              createLabel="+"
              optionText={(choice) => (
                <CustomTooltip title={choice.tooltip} arrow>
                  <span>{choice.name}</span>
                </CustomTooltip>
              )}
              optionValue="id"
              fullWidth
              resettable
              sx={{ width: '30%', marginBottom: '15px' }}
              render={(props: any) => (
                <TextField select {...props}>
                  {tags.map((tag: any) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      <CustomTooltip title={tag.tooltip} arrow>
                        <span>{tag.name}</span>
                      </CustomTooltip>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
            <TextInput sx={{ width: '50%' }} source='notes' />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default SimCardEdit
