import React from 'react';
import { CreateButton, ExportButton, FilterButton, SelectColumnsButton, TopToolbar } from 'react-admin';

const TaskListActions = () => {
  return (
    <>
      <TopToolbar>
        <SelectColumnsButton />
        <CreateButton />
        {/*<FilterButton />*/}
        {/*<ExportButton />*/}
      </TopToolbar>
    </>
  );
};

export default TaskListActions;
