const SimCardsRowStyle = (record: any, theme: string) => {
  const currentDate = new Date();
  const expirationDate = new Date(record.expirationPeriod);

  const timeDifference = expirationDate.getTime() - currentDate.getTime();
  const daysUntilExpiration = timeDifference / (1000 * 3600 * 24);

  let style = {};

  if (!record) {
    return style;
  }

  if (daysUntilExpiration <= 30) {
    if (theme === 'dark') {
      style = {
        ...style,
        backgroundColor: 'rgba(255, 165, 0, 0.3)'
      };
    } else {
      style = {
        ...style,
        backgroundColor: 'rgba(255, 165, 0, 0.3)'
      };
    }
  }

  if (daysUntilExpiration <= 7) {
    if (theme === 'dark') {
      style = {
        ...style,
        backgroundColor: 'rgba(65,6,6,0.55)'
      };
    } else {
      style = {
        ...style,
        backgroundColor: '#FFC0CB'
      };
    }
  }

  return style;
};

export default SimCardsRowStyle;
