import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  Create,
  DateInput, DateTimeInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetList,
} from 'react-admin';
import { Grid, MenuItem } from '@mui/material';
import CreateTag from '../tags/CreateTag';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: '16px',
  },
});

const TaskCreate = (props: any) => {
  const [tags, setTags] = useState([]);

  const tagsList: any = useGetList(
    'tags?type=tasks&',
    {
      sort: { field: 'login', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (tagsList.data) {
      setTags(tagsList.data?.map((record: any) => ({
        id: record._id,
        name: record.name,
        tooltip: record.description,
      })));
    }
  }, [tagsList.data]);

  return (
    <Create title='Create Task' {...props}>
      <SimpleForm>
        <Grid container sx={{ pl: '10px', pr: '30px' }}>
          <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
            <TextInput source='name' required />
            <TextInput source='assignee' />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
            <TextInput
              source='description'
              sx={{ width: '40%' }}
              fullWidth
              multiline
              minRows={2}
              resettable
            />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
            <AutocompleteArrayInput
              {...props}
              source='tags'
              helperText={false}
              choices={tags}
              create={<CreateTag />}
              createLabel='+'
              optionText={(choice) => (
                <CustomTooltip title={choice.tooltip} arrow>
                  <span>{choice.name}</span>
                </CustomTooltip>
              )}
              optionValue='id'
              fullWidth
              resettable
              sx={{ width: '40%', marginBottom: '15px' }}
              render={(props: any) => (
                <TextField select {...props}>
                  {tags.map((tag: any) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      <CustomTooltip title={tag.tooltip} arrow>
                        <span>{tag.name}</span>
                      </CustomTooltip>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
            <SelectInput
              source='status'
              choices={[
                { id: 'pending', name: 'Pending' },
                { id: 'in progress', name: 'In progress' },
                { id: 'completed', name: 'Completed' },
                { id: 'canceled', name: 'Canceled' },
              ]}
            />
            <SelectInput
              source='priority'
              choices={[
                { id: 'high', name: 'High' },
                { id: 'medium', name: 'Medium' },
                { id: 'low', name: 'Low' },
              ]}
            />
            <DateTimeInput source='dueDate' />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
            <TextInput
              source='comment'
              sx={{ width: '40%' }}
              fullWidth
              multiline
              minRows={3}
              resettable
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default TaskCreate;
