import { Filter, TextInput } from 'react-admin';
import React from 'react';

const AccountsFilter = (props: any) => {
  return (
    <Filter {...props} >
      <TextInput placeholder='Account name' source='name' resettable alwaysOn />
      <TextInput placeholder='Account email' source='email' resettable alwaysOn />
      <TextInput placeholder='Account phone' source='phoneNumber' resettable alwaysOn />
      {/*<SelectInput*/}
      {/*  {...props}*/}
      {/*  source="tags"*/}
      {/*  helperText={false}*/}
      {/*  choices={tags}*/}
      {/*  create={<CreateTag />}*/}
      {/*  createLabel="+"*/}
      {/*  optionText={(choice) => (*/}
      {/*    <CustomTooltip title={choice.tooltip} arrow>*/}
      {/*      <span>{choice.name}</span>*/}
      {/*    </CustomTooltip>*/}
      {/*  )}*/}
      {/*  optionValue="id"*/}
      {/*  fullWidth*/}
      {/*  resettable*/}
      {/*  sx={{ width: '80%', marginTop: '0px' }}*/}
      {/*  render={(props: any) => (*/}
      {/*    <TextField select {...props}>*/}
      {/*      {tags.map((tag: any) => (*/}
      {/*        <MenuItem key={tag.id} value={tag.id}>*/}
      {/*          <CustomTooltip title={tag.tooltip} arrow>*/}
      {/*            <span>{tag.name}</span>*/}
      {/*          </CustomTooltip>*/}
      {/*        </MenuItem>*/}
      {/*      ))}*/}
      {/*    </TextField>*/}
      {/*  )}*/}
      {/*/>*/}
    </Filter>
  )
};

export default AccountsFilter;
