import { Filter, SelectInput, TextInput } from 'react-admin';
import React from 'react';

const SimCardsFilter = (props: any) => {
  return (
    <Filter {...props} >
      <TextInput placeholder='Phone number' source='phoneNumber' resettable alwaysOn />
      <SelectInput placeholder='Owner'  source='owner' choices={[{ id: 'Євгенія', name: 'Євгенія' }, { id: 'Діана', name: 'Діана' }, { id: 'Денис', name: 'Денис' }]} alwaysOn resettable />
    </Filter>
  )
};

export default SimCardsFilter;
