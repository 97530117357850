import * as React from 'react';
import restProvider from 'ra-data-json-server';
import {
  Admin,
  Resource,
  defaultTheme, defaultDarkTheme,
} from 'react-admin';
import { MyLayout } from './layout/Layout';
import authProvider from './AuthProvider';
import AccountList from './components/account/AccountList';
import AccountCreate from './components/account/AccountCreate';
import AccountEdit from './components/account/AccountEdit';
import SimCardList from './components/simCards/SimCardList';
import SimCardCreate from './components/simCards/SimCardCreate';
import SimCardEdit from './components/simCards/SimCardEdit';
import TaskList from './components/tasks/TaskList';
import TaskCreate from './components/tasks/TaskCreate';
import TaskEdit from './components/tasks/TaskEdit';

export const API_URL = 'https://mirapanelapps.com/farm-back/backend';
// export const API_URL = `${process.env.REACT_APP_API_URL}`;

const App = () => {
  return (
    <Admin
      layout={MyLayout}
      authProvider={authProvider}
      dataProvider={restProvider(API_URL)}
      theme={defaultTheme}
      darkTheme={defaultDarkTheme}
    >
      <Resource
        name='accounts'
        list={AccountList}
        create={AccountCreate}
        edit={AccountEdit}
      />
      <Resource
        name='sim-cards'
        list={SimCardList}
        create={SimCardCreate}
        edit={SimCardEdit}
      />
      <Resource
        name='tasks'
        list={TaskList}
        create={TaskCreate}
        edit={TaskEdit}
      />
    </Admin>
  );
};

export default App;
